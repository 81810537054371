<div *ngIf="displayAlert" class="alert-container" [@fadeAndCollapseOnLeave]
  [ngClass]="{'info-container': type === alertTypes.INFORMATION, 'warning-container': type === alertTypes.WARNING, 'error-container': type === alertTypes.ERROR, 'success-container': type === alertTypes.SUCCESS}">

  <div class="tw-flex">
    <ng-container *ngIf="showIcon" [ngSwitch]="type">
      <fdl-svg-sprite [icon]="'info-circle-fill'" class="icon tw-fill-bg-info" *ngSwitchCase="alertTypes.INFORMATION"></fdl-svg-sprite>
      <fdl-svg-sprite [icon]="'warning-fill'" class="icon tw-fill-bg-warning" *ngSwitchCase="alertTypes.WARNING"></fdl-svg-sprite>
      <fdl-svg-sprite [icon]="'danger-fill'" class="icon tw-fill-bg-danger" *ngSwitchCase="alertTypes.ERROR"></fdl-svg-sprite>
      <fdl-svg-sprite [icon]="'check-circle-fill'" class="icon tw-fill-bg-success" *ngSwitchCase="alertTypes.SUCCESS"></fdl-svg-sprite>
    </ng-container>

    <div *ngIf="message; else customContent">
      <div *ngIf="header" class="message header" [innerHTML]="header"></div>
      <div class="message" [innerHTML]="message"></div>
    </div>

    <ng-template #customContent>
      <div class="message">
        <ng-content></ng-content>
      </div>
    </ng-template>
  </div>

  <div class="tw-flex">
    <fdl-svg-sprite (click)="dismissAlert()" *ngIf="dismissible" [icon]="'cross'" class="icon-cross"></fdl-svg-sprite>
  </div>
</div>