import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FdlButtonComponent } from '@fyle/button';
import { ButtonType } from '@fyle/model-shared';
import { ModalActionType } from './modal-action.model';

@Component({
  selector: 'fdl-modal-actions',
  standalone: true,
  imports: [CommonModule, FdlButtonComponent],
  templateUrl: './modal-actions.component.html',
  styleUrls: ['./modal-actions.component.scss'],
})
export class FdlModalActionsComponent {
  @Input() primaryAction: ModalActionType;

  @Input() secondaryAction: ModalActionType;

  buttonType = ButtonType;
}
