<div
  class="fdl-modal-header"
  [ngClass]="{
    'tw-p-spacing-24': type === modalHeaderTypes.NORMAL,
    'tw-p-spacing-20': type !== modalHeaderTypes.NORMAL
  }"
>
  <div class="items-container">
    <div
      class="icon-container"
      *ngIf="type !== modalHeaderTypes.NORMAL"
      [ngClass]="{
        'tw-bg-bg-danger-light': type === modalHeaderTypes.DANGER,
        'tw-bg-bg-success-light': type === modalHeaderTypes.SUCCESS,
        'tw-bg-bg-warning-lighter': type === modalHeaderTypes.WARNING
      }"
    >
      <fdl-svg-sprite
        *ngIf="type === modalHeaderTypes.DANGER"
        icon="bin"
        classes="tw-w-16-px tw-h-16-px tw-fill-icon-danger"
      ></fdl-svg-sprite>
      <fdl-svg-sprite
        *ngIf="type === modalHeaderTypes.SUCCESS"
        icon="check"
        classes="tw-w-16-px tw-h-16-px tw-fill-icon-success"
      ></fdl-svg-sprite>
      <fdl-svg-sprite
        *ngIf="type === modalHeaderTypes.WARNING"
        icon="warning-outline"
        classes="tw-w-16-px tw-h-16-px tw-fill-icon-warning"
      ></fdl-svg-sprite>
    </div>
    <div class="title">{{title}}</div>
    <div class="close" (click)="close()" title="close">
      <fdl-svg-sprite icon="cross" classes="tw-w-16-px tw-h-16-px tw-fill-icon-muted"></fdl-svg-sprite>
    </div>
  </div>
</div>
