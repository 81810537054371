<div class="auth-container">

  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card">
      <div *ngIf="isAPIInProgress" class="progress-spinner">
        <p-progressSpinner styleClass="spinner-50" strokeWidth="5"></p-progressSpinner>
      </div>

      <ng-container *ngIf="!isAPIInProgress && !isRequestSent && canRequest">
        <div class="content-container">
          <div class="tw-flex-no-shrink">
            <h3 class="title">Your organization uses {{ targetConfig.BRAND_NAME }}</h3>
            <p class="description">It looks like your organization is already using {{ targetConfig.BRAND_NAME }}. You can request the admin to invite you to the organization's {{ targetConfig.BRAND_NAME }} account.</p>
          </div>

          <fdl-button 
            [buttonType]="buttonType.PRIMARY"
            [customClasses]="'button-full font-700 padding-14 text-uppercase'"
            [label]="'Request Invite'"
            (buttonClick)="requestInvite()">
          </fdl-button>
        </div>
      </ng-container>

      <ng-container *ngIf="!isAPIInProgress && isRequestSent && canRequest">

        <div class="success-message">
          <app-svg-sprite icon="check" class="icon-success" classes="tw-h-64-px"></app-svg-sprite>
          <h3 class="title">Invitation requested</h3>
          <p class="description">
            An invitation request has been sent to your company's admin. You will receive an email as soon as the admin accepts your request.
          </p>
        </div>

      </ng-container>

      <div *ngIf="!isAPIInProgress && !canRequest" class="error-container">
        <h3 class="title">Error sending request</h3>
        <p class="description">Your request has already been sent to your organization's admin.</p>

        <p class="tw-mt-0 message">You cannot request an invitation again. Maybe try <a href="#/signup?asset=webapp">signing up</a> or <a href="#/signin?asset=webapp">signing in</a>?</p>
      </div>
    </div>
    <div *ngIf="!isAPIInProgress" class="action-alternate">
      Back to <a href="#/signup?asset=webapp">Sign Up</a>
    </div>
  </div>
</div>