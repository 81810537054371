import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { UserEventService } from '../services/user-event.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;

  private refreshTokenSubject$ = new BehaviorSubject<string | null>(null);

  constructor(
    private jwtHelpter: JwtHelperService,
    private tokenService: TokenService,
    private authService: AuthService,
    private userEventService: UserEventService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if calling api server, and not requesting for access token
    if (this.isFyleEndpoint(request.url) && !this.isEndpointWithoutToken(request.url)) {
      return this.getAccessToken().pipe(
        switchMap((accessToken) => {
          /**
           * @description Access token will be null, if there's no refresh token.
           * There will be no refresh token, if the user is not logged in.
           * Few api's like request_invitation, reset_password don't require access token since the user is
           * not logged in. In such cases, the http request should be executed without access token.
           */
          if (accessToken) {
            return this.executeHttpRequest(request, next, accessToken);
          }
          return this.executeHttpRequestWithoutToken(request, next);
        }),
      );
    } else {
      return this.executeHttpRequestWithoutToken(request, next);
    }
  }

  private executeHttpRequestWithoutToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ url: request.url, withCredentials: true });
    return next.handle(request);
  }

  private executeHttpRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    accessToken: string,
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      url: request.url,
      headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
      withCredentials: true,
    });
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private isFyleEndpoint(url: string): boolean {
    return (
      url.includes('.fylehq.com') || url.includes('.fyle.tech') || url.includes('.fyle.io') || url.includes('localhost')
    );
  }

  private isEndpointWithoutToken(url: string): boolean {
    return (url.includes('/api/auth/') || url.includes('/routerapi/auth/')) && !url.includes('/api/auth/logout');
  }

  private isTokenExpiring(accessToken: string): boolean {
    try {
      const expiryDate = this.jwtHelpter.getTokenExpirationDate(accessToken);
      const now = Date.now();
      const differenceSeconds = Math.floor((expiryDate.getTime() - now) / 1000);
      const maxRefreshDifferenceSeconds = 2 * 60;
      return differenceSeconds < maxRefreshDifferenceSeconds;
    } catch (err) {
      return true;
    }
  }

  private getNewAccessToken(): Observable<string | null> {
    const refreshToken = this.tokenService.getRefreshToken();

    if (refreshToken) {
      return this.authService.getNewAccessToken(refreshToken).pipe(
        catchError((error) => this.handleError(error)),
        map((res) => this.tokenService.getAccessToken()),
      );
    } else {
      return of(null);
    }
  }

  /**
   * @description This method get current accessToken from Storage, check if this token is expiring or not.
   * If the token is expiring it will get another accessToken from API and return the new accessToken
   * If multiple API call initiated then `this.refreshTokenInProgress` will block multiple access_token call
   * @link https://stackoverflow.com/a/57638101
   */
  private getAccessToken(): Observable<string | null> {
    const accessToken = this.tokenService.getAccessToken();

    if (accessToken && !this.isTokenExpiring(accessToken)) {
      return of(accessToken);
    }

    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject$.next(null);
      return this.getNewAccessToken().pipe(
        switchMap((newAccessToken) => {
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject$.next(newAccessToken);
          return of(newAccessToken);
        }),
      );
    } else {
      return this.refreshTokenSubject$.pipe(
        filter((result) => result !== null),
        take(1),
        map(() => this.tokenService.getAccessToken()),
      );
    }
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (
      (error.status === 401 || error.status === 498 || error.status === 440) && //when session_settings are enabled, after a certain time of inactivity, /access_token api throws 401 error -  this is a secure url
      this.isFyleEndpoint(error.url) &&
      (!this.isEndpointWithoutToken(error.url) || error.url.includes('/api/auth/access_token'))
    ) {
      this.userEventService.logout();
    }
    return throwError(() => error);
  }
}
