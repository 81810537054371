<div class="auth-container tw-items-center tw-justify-center">
  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card tw-items-center">
      <div class="icon-bg">
        <app-svg-sprite icon="danger-outline" class="tw-h-16-px tw-w-16-px"></app-svg-sprite>
      </div>
      <h3 class="header">Account Disabled</h3>
      <div class="description text-center">
        <div>This account <strong *ngIf="emailId">{{ emailId }}</strong> is no longer active.</div>
        <div>Please contact your company admin for details.</div>
      </div>
      <div class="action-alternate text-center">
        <div class="tw-mb-8-px">Wrong account? </div>
        <div class="alternate-description"><span class="action cursor-pointer" (click)="goToSignin()">Sign In</span> using another account</div>
      </div>
    </div>
  </div>
  <footer class="footer text-center">
    Never lose track
  </footer>
</div>
