import { CommonModule, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, Renderer2, ElementRef, TemplateRef } from '@angular/core';
import { ButtonType, ButtonSize } from '@fyle/model-shared';
import { SvgSpriteComponent } from '@fyle/svg-sprite';
import { ButtonModule } from 'primeng/button';
import { FormValidationButtonLoaderDirective } from '@fyle/form-validation-button-loader';
import { FormGroup } from '@angular/forms';
import { FdlTooltipDirective } from '@fyle/tooltip';
@Component({
  selector: 'fdl-button',
  templateUrl: './button.component.html',
  standalone: true,
  imports: [ButtonModule, SvgSpriteComponent, CommonModule, FormValidationButtonLoaderDirective, FdlTooltipDirective],
  styleUrls: ['./button.component.scss'],
})
export class FdlButtonComponent {
  @Input({ required: true }) buttonType: ButtonType;

  @Input({ required: true }) label: string;

  //default size is regular. It is passed as input only when the buttom type is small
  @Input() buttonSize = ButtonSize.REGULAR;

  @Input() customClasses: string;

  @Input() isDisabled = false;

  @Input() isLoading = false;

  @Input() icon: string = '';

  @Input() iconClasses: string = '';

  @Input() iconContainerClasses: string = '';

  @Input() defaultType: string = 'button';

  @Input() loadingText: string;

  @Input() validateForm: boolean = false;

  @Input() formToValidate: FormGroup;

  @Input() formElement: HTMLFormElement;

  @Input() tooltip: string;

  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right';

  @Input() customPrefixTemplate: TemplateRef<NgIf>;

  @Output() buttonClick = new EventEmitter<{ label: string; event?: Event }>();

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  onClick(event: Event) {
    this.buttonClick.emit({ label: this.label, event: event });
  }
}
