<fdl-modal [size]="modalSize.SMALL">
  <fdl-modal-header
    [type]="modalConfiguration.header.type"
    [title]="modalConfiguration.header.title"
    (closeModal)="close()"
  >
  </fdl-modal-header>

  <fdl-modal-content class="tw-grow tw-p-spacing-24">
    <div *ngIf="customTemplate; else textContent">
      <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
    </div>
    <ng-template #textContent>
      <div [innerHTML]="modalConfiguration.content"></div>
    </ng-template>
    <div class="tw-mt-spacing-16" [innerHTML]="modalConfiguration.confirmationMessage  ? modalConfiguration.confirmationMessage : defaultConfirmationMessage"></div>
  </fdl-modal-content>
  <fdl-modal-actions
    [primaryAction]="modalConfiguration.actions.primaryAction"
    [secondaryAction]="modalConfiguration.actions.secondaryAction"
  >
  </fdl-modal-actions>
</fdl-modal>
