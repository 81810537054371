<div class="fdl-modal-actions">
  <div class="action-container">
    <fdl-button *ngIf="secondaryAction"
      [buttonType]="secondaryAction.type"
      [defaultType]="'submit'"
      [validateForm]="true"
      [label]="secondaryAction.label"
      [isLoading]="secondaryAction.loading"
      [loadingText]="secondaryAction.loadingText"
      [isDisabled]="secondaryAction.disabled"
      (buttonClick)="secondaryAction.callbackMethod()"
    ></fdl-button>
    <fdl-button *ngIf="primaryAction"
      [buttonType]="primaryAction.type"
      [defaultType]="'submit'"
      [label]="primaryAction.label"
      [validateForm]="true"
      [isLoading]="primaryAction.loading"
      [loadingText]="primaryAction.loadingText"
      [isDisabled]="primaryAction.disabled"
      (buttonClick)="primaryAction.callbackMethod()"
    ></fdl-button>
  </div>
</div>
