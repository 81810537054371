<div class="search-toolbar-new" (click)="showSearchBarFn(true)">
  <div class="search-box">
    <app-svg-sprite icon="search" 
      [ngClass]="{'max-lg:tw-animate-search-icon-reveal-animation max-lg:tw-opacity-0' : showSearchBar, 'tw-cursor-pointer': !showSearchBar}" 
      class="search-icon">
    </app-svg-sprite>
    <input
      #searchBox
      name="simple-search"
      autocomplete="off"
      type="text"
      (input)="onInputChange($event)"
      [(ngModel)]="value"
      placeholder="{{placeholder}}"
      class="form-control"
      [ngClass]="{'searchbar-width tw-cursor-pointer' : !showSearchBar , 'form-control-open max-lg:tw-animate-search-bar-animation-fade-in' : showSearchBar}"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [disabled]="disabled"
      id="animation-search-box">
    <app-svg-sprite *ngIf="this.value" 
      icon="cross" 
      class="cross-icon" 
      (click)="clearInput()">
    </app-svg-sprite>
  </div>
</div>