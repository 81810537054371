<div class="auth-container reset-password-container">

  <div class="card-container">

    <app-header></app-header>

    <div *ngIf="!hasRequestedResetPasswordLink" class="auth-card">

      <div class="content-container">

        <h3 class="title">Forgot Password</h3>

        <p class="sub-title">Enter your registered email address, we'll send you a link to set the new password</p>

        <form class="form-container">
          
          <div class="email-input-container">

            <div class="label-container">
              <div class="input-label">Work Email</div>
              <span class="asterisk">*</span> 
            </div>

            <div class="validation-wrapper tw-relative">

              <input 
                class="input-content input-text"
                autocomplete="off"
                [formControl]="resetPasswordForm"
                name="email" 
                type="email" 
                placeholder="Your Email Address"
                appAutofocus
                [ngClass]="{'p-error' : resetPasswordForm.touched && resetPasswordForm.invalid}"
                >

              <div class="error-space">
              </div>
              
              <div *ngIf="resetPasswordForm.valid" class="action-wrapper" >
                <span class="action-text">
                  <app-svg-sprite icon="check" class="success-icon"></app-svg-sprite>
                </span>
              </div>
              
              <div *ngIf="resetPasswordForm.touched && resetPasswordForm.errors" class="action-wrapper">
                <span class="action-text">
                <app-svg-sprite icon="danger-fill" class="error-icon"></app-svg-sprite>
                </span>
              </div>
              
            </div>

            <ng-container *ngIf="resetPasswordForm.touched && resetPasswordForm.errors">
              <div *ngIf="resetPasswordForm.hasError('required')" class="error">Please enter an email address</div>
              <div *ngIf="resetPasswordForm.hasError('email')" class="error">Please enter a valid email address</div>
            </ng-container>

          </div>

          <fdl-button
            [defaultType]="'submit'"
            [buttonType]="buttonType.PRIMARY"
            (buttonClick)="sendResetLink()"
            [validateForm]="true"
            [isLoading]="showLoaderOnButton"
            [label]="'Request Reset Link'"
            [customClasses]="'button-full font-700 padding-14'"
            [loadingText]="'Requesting'">
          </fdl-button>

          <div class="alternate-text" (click)="goToSignIn()">
            <p>&#60;</p>
            <div>Back to sign in</div>
          </div>

        </form>

      </div>
    </div>

    <div *ngIf="hasRequestedResetPasswordLink" class="auth-card">

      <div class="content-container tw-text-center">

        <app-svg-sprite icon="reset-email" class="reset-email"></app-svg-sprite>

        <h3 class="check-email-text">Check Your Mail</h3>

        <div class="sent-mail">

          <p *ngIf="!canChangeContent" class="tw-m-0 tw-p-0 message-1">We have sent a reset link on your email address,</p>
          
          <p *ngIf="canChangeContent" class="tw-m-0 tw-p-0 message-1">If there's a {{ targetConfig.BRAND_NAME }} account linked to this email address, we'll send over instructions to reset your password.</p>
          
          <p class="email">{{ this.resetPasswordForm.value }}</p>

        </div>

        <div *ngIf="!canChangeContent" class="link-text">
          <p class="tw-p-0 tw-m-0 message-2">Didn't receive a link?</p>
          <p-progressSpinner *ngIf="!isResendAPI" 
            styleClass="spinner-14" 
            strokeWidth="5"
            class="progress-spinner"
            >
          </p-progressSpinner>
          <p *ngIf="isResendAPI" class="resend-link" (click)="sendResetLink()">Resend Link</p>
        </div>
      </div>

      <div class="or-text-container">
        <span class="or-text">
          OR
        </span>
      </div>

      <fdl-button
        [defaultType]="'submit'"
        [buttonType]="buttonType.PRIMARY"
        [customClasses]="'text-uppercase font-700 padding-14 button-full'"
        [label]="'Sign In'"
        (buttonClick)="goToSignIn()">
      </fdl-button>

    </div>
      
  </div>

  <footer class="footer">Never lose track</footer>
</div>
