<div class="password-rules-container">

  <ul class="rules tw-list-none">

    <ng-container *ngFor="let error of errorMapping">
      <li class="condition-list">
        <app-svg-sprite *ngIf="!passwordStrength || !passwordStrength[error.type]" icon="check" classes="tw-h-14-px tw-w-14-px" class="icon icon-checkmark"></app-svg-sprite>
        <app-svg-sprite *ngIf="passwordStrength && passwordStrength[error.type]" icon="cross" classes="tw-h-14-px tw-w-14-px" class="icon"></app-svg-sprite>
        <span class="text">{{ error.message }}</span>
      </li>  
    </ng-container>

  </ul>

</div>
