<div class="custom-dialog-popup">

  <app-dialog-header [title]="title" [titleTheme]="titleTheme">
  </app-dialog-header>

  <div>

    <div class="dialog-content">
    
      <ul *ngIf="consequences?.length > 0" class="consequences-msg">
        <li class="consequence-msg" *ngFor="let consequence of consequences">
            {{consequence}}
        </li>
      </ul>
      
      <div *ngIf="isPrompt" class="input-container">
        <div class="tw-flex tw-flex-row">
          <label class="input-label">{{label}}</label>
          <span *ngIf="required" class="asterisk">*</span>
        </div>
        <textarea 
          *ngIf="inputType === 'textarea'" 
          class="prompt-input"
          [ngClass]="prompt.touched && prompt.invalid ? 'input-error' : ''"
          appAutofocus
          [formControl]="prompt" 
          [placeholder]="placeholder" 
          rows="3" 
          max-rows="4"
          >
        </textarea>

        <input
          *ngIf="!inputType || inputType === 'input'" 
          class="prompt-input"
          [ngClass]="prompt.touched && prompt.invalid ? 'input-error' : ''"
          appAutofocus
          [formControl]="prompt" 
          [placeholder]="placeholder"
        >

        <ng-container *ngIf="prompt.touched && prompt.invalid">
          <div *ngIf="prompt.hasError('required')" class="input-error-message">{{requiredErrorMessage}}</div>
          <div *ngIf="prompt.hasError('maxlength')" class="input-error-message">{{lengthErrorMessage}}</div>
        </ng-container>

    </div>

      <div class="confirmation-msg" [ngClass]="{'!tw-m-0' : !consequences || consequences.length === 0 || inputType === 'input' }" [innerHTML]="confirmationMsg"></div>

    </div>

    <div class="button-actions-block">
      <fdl-button  
        *ngIf="type !== dialogTypes.ALERT && cancelButtonText" 
        [defaultType]="'button'" 
        (buttonClick)="close('cancel')" 
        [buttonType]="buttonType.OUTLINE_SECONDARY"
        [label]="cancelButtonText">
      </fdl-button>

      <fdl-button  
        [defaultType]="'submit'" 
        (buttonClick)="hide()"
        [buttonType]="buttonType.PRIMARY"
        [customClasses]="'tw-ml-spacing-16'"
        [label]="okButtonText">
      </fdl-button>
    </div>
  </div>
</div>