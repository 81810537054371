import { Injectable, Type } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FdlDialogConfig } from './fdl-dialog-config.model';
import { ConfirmationModalConfig } from './modal/confirmation-modal/confirmation-modal.model';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
@Injectable({
  providedIn: 'root',
})
export class FdlDialogService {
  defaultDialogConfiguration: FdlDialogConfig = {
    showHeader: false,
    contentStyle: { padding: 0 },
  };

  constructor(private dialogService: DialogService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open(dynamicComponent: Type<any>, dialogConfig?: FdlDialogConfig): DynamicDialogRef {
    return this.dialogService.open(dynamicComponent, {
      ...this.defaultDialogConfiguration,
      ...dialogConfig,
    });
  }

  openConfirmationModal(modalConfig: ConfirmationModalConfig): DynamicDialogRef {
    return this.open(ConfirmationModalComponent, { data: modalConfig });
  }
}
