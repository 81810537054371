import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { ActiveOrgCardComponent } from './components/active-org-card/active-org-card.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HeaderComponent } from './components/header/header.component';
import { IntermediateLoaderComponent } from './components/intermediate-loader/intermediate-loader.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { RandomImagesComponent } from './components/random-images/random-images.component';
import { ShimmersComponent } from './components/shimmers/shimmers.component';
import { SimpleSearchComponent } from './components/simple-search/simple-search.component';
import { SvgSpriteComponent } from './components/svg-sprite/svg-sprite.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { DialogHeaderComponent } from './dialogs/dialog-header/dialog-header.component';
import { DialogComponent } from './dialogs/dialog/dialog.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FdlButtonComponent } from '@fyle/button';
import { FdlAlertComponent } from '@fyle/alert';
import { FormValidationButtonLoaderDirective } from './directives/form-validation-button-loader.directive';
import { FdlTooltipDirective } from '@fyle/tooltip';

@NgModule({
  declarations: [
    ActiveOrgCardComponent,
    DropdownComponent,
    HeaderComponent,
    IntermediateLoaderComponent,
    LoadingOverlayComponent,
    PasswordRulesComponent,
    RandomImagesComponent,
    ShimmersComponent,
    SimpleSearchComponent,
    SvgSpriteComponent,
    ToastMessageComponent,
    DialogComponent,
    DialogHeaderComponent,
    AutofocusDirective,
    FormValidationButtonLoaderDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    RippleModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    InputTextModule,
    CheckboxModule,
    ListboxModule,
    HttpClientModule,
    ButtonModule,
    ToastModule,
    DropdownModule,
    FdlButtonComponent,
    FdlAlertComponent,
    FdlTooltipDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    RippleModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    InputTextModule,
    CheckboxModule,
    ListboxModule,
    HttpClientModule,
    ButtonModule,
    ToastModule,
    DropdownModule,
    ActiveOrgCardComponent,
    DropdownComponent,
    HeaderComponent,
    IntermediateLoaderComponent,
    LoadingOverlayComponent,
    PasswordRulesComponent,
    RandomImagesComponent,
    ShimmersComponent,
    SimpleSearchComponent,
    SvgSpriteComponent,
    ToastMessageComponent,
    DialogComponent,
    DialogHeaderComponent,
    AutofocusDirective,
    FormValidationButtonLoaderDirective,
    FdlButtonComponent,
    FdlAlertComponent,
    FdlTooltipDirective,
  ],
})
export class SharedModule {}
