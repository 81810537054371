<div class="title-container" [ngClass]="titleTheme">
  <div class="title">
    <app-svg-sprite *ngIf="titleTheme === dialogThemes.WARN" icon="warning-fill" classes="tw-w-18-px tw-mr-8-px tw-fill-gamboge"></app-svg-sprite>
    <app-svg-sprite *ngIf="titleTheme === dialogThemes.DANGER" icon="danger-fill" classes="tw-w-20-px tw-mr-8-px tw-align-text-bottom tw-fill-fire-engine-red"></app-svg-sprite>
    <app-svg-sprite *ngIf="titleTheme === dialogThemes.INFO" icon="info-circle-fill" classes="tw-w-20-px tw-mr-8-px tw-fill-cornflower-blue"></app-svg-sprite>
    <app-svg-sprite *ngIf="titleTheme === dialogThemes.SUCCESS" icon="check" classes="tw-w-18-px tw-mr-8-px tw-fill-white tw-bg-chateau-green tw-rounded-1/2 tw-p-4-px"></app-svg-sprite>
    {{ title }}
    <span class="cursor-pointer" (click)="close('close')" title="close">
      <app-svg-sprite icon="cross" classes="tw-w-14-px tw-h-14-px tw-fill-mischka"></app-svg-sprite>
    </span>
  </div>
</div>