<div class="auth-container">
  
  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card tw-mb-0">
      <app-loading-overlay *ngIf="showOverlay">
        <span>Checking your CapitalOne credentials</span>
      </app-loading-overlay>

      <div *ngIf="errorState" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <app-svg-sprite 
          class="icon-bg tw-flex tw-items-center tw-justify-center" 
          icon="danger-outline" 
          classes="tw-w-16-px tw-h-16-px">
        </app-svg-sprite>

        <h3 class="header">{{header}}</h3>

        <div class="description">
          <ng-container *ngFor="let description of descriptions">
            <div>{{ description }}</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</div>
